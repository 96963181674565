<template>
  <div class="page-content">
    <div class="container-fluid">
      <!-- start page title -->
      <div class="row">
        <div class="col-12">
          <div class="page-title-box d-sm-flex align-items-center justify-content-between">
            <h4 class="mb-sm-0 font-size-18">Quản lý Jobs Instagram</h4>

            <div class="page-title-right">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item">
                  <a href="javascript: void(0);">Admin Traodoicheo</a>
                </li>
                <li class="breadcrumb-item active">Quản lý Jobs Instagram</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <!-- end page title -->
      <user-online-vue />
      <div class="row">
        <div class="col-12">
          <div class="card py-4">
            <div class="container-fluid">
              <div class="row">
                <form @submit.prevent="getJobList" class="col-md-3 mb-4">
                  <div class="form-group">
                    <label for="">Tìm theo Object Id</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="nhập object id"
                      v-model="object_id"
                    />
                  </div>
                </form>
                <form @submit.prevent="getJobList" class="col-md-3 mb-4">
                  <div class="form-group">
                    <label for="">Tìm theo Order Id</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="nhập order id"
                      v-model="order_id"
                    />
                  </div>
                </form>
                <form @submit.prevent="getJobList" class="col-md-3 mb-4">
                  <div class="form-group">
                    <label for="">Tìm theo User Id</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="nhập user id"
                      v-model="user_id"
                    />
                  </div>
                </form>
                <form class="col-md-3 mb-4">
                  <div class="form-group">
                    <label for="">Trạng thái</label>
                    <select class="form-select" v-model="is_hidden" @change="getJobList">
                      <option value="">Tất cả</option>
                      <option value="0">Đang mở</option>
                      <option value="1">Đang ẩn</option>
                    </select>
                  </div>
                </form>
                <form class="col-md-3">
                  <div class="form-group">
                    <label for="">Limit</label>
                    <select class="form-select" v-model="limit" @change="getJobList">
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="200">200</option>
                      <option value="1000">1000</option>
                    </select>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <loading-vue v-if="loading" />
      <div class="row">
        <div class="col-md-12">
          <div class="card text-left">
            <img class="card-img-top" src="holder.js/100px180/" alt="" />
            <div class="card-body">
              <h4 class="card-title">Thao tác nhanh</h4>
              <p class="card-text">
                <a @click="openAllJobHidden()" class="btn btn-success mt-2" role="button">
                  Mở tất cả job ẩn
                </a>
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <datatable
            :stt="true"
            :title="'Danh sách Jobs Instagram'"
            :columns="columns"
            :rows="manipulatedJobs"
            :defaultPerPage="10"
          >
            <th slot="thead-tr-o" class="text-center" style="vertical-align: middle">Thao Tác</th>
            <td slot="tbody-tr-o" class="text-center" slot-scope="props">
              <button
                class="btn btn-primary font-10 py-1 btn-xs me-1"
                @click="setPriorityJob(props.row)"
              >
                Ưu tiên
              </button>
              <button
                class="btn btn-danger font-10 py-1 btn-xs"
                v-if="props.row.is_hidden === 0"
                @click="hideJob(props.row)"
                :disabled="props.row.disabled"
              >
                Ẩn
              </button>
              <button
                class="btn btn-success font-10 py-1 btn-xs"
                v-if="props.row.is_hidden === 1"
                @click="unHideJob(props.row)"
                :disabled="props.row.disabled"
              >
                Mở
              </button>
              <button
                class="btn btn-warning font-10 py-1 btn-xs ms-1"
                @click="removeJob(props.row)"
                :disabled="props.row.disabled"
              >
                Xóa
              </button>
            </td>
          </datatable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */
import jobApi from "@/api/job";
import Datatable from "@/components/datatable/Datatable.vue";
import LoadingVue from "@/components/loading/Loading.vue";
import { buildParamQuery } from "@/helpers/index";
import datetimeHelper from "@/helpers/datetime";
import UserOnlineVue from "@/components/user/UserOnline.vue";
export default {
  name: "JobsInstagram",
  components: {
    Datatable,
    LoadingVue,
    UserOnlineVue,
  },
  data() {
    return {
      order_id: null,
      user_id: null,
      object_id: null,
      is_hidden: "",
      limit: 50,
      jobs: [],
      loading: false,
      columns: [
        { label: "Orders Id", field: "orders_id" },
        { label: "Object Id", field: "object_id" },
        { label: "Object Id Link", field: "link", ig_link_full: true },
        { label: "Hidden Status", field: "hidden_name" },
        { label: "User Id", field: "user_id" },
        { label: "Mfb username", field: "mfb_username" },
        { label: "Số lượng", field: "quantity" },
        { label: "Package Name", field: "package_name" },
        { label: "Type", field: "type" },
        { label: "Status message", field: "status_message" },
        { label: "Start", field: "start", numeric: true },
        { label: "Ngày duyệt", field: "time_payment", timeago: true },
        { label: "Ngày thanh toán", field: "time_payment", timeago: true },
        { label: "Ngày kết thúc chạy", field: "time_end", timeago: true },
        { label: "Số lần đã thành toán", field: "paid", numeric: true },
        { label: "Ngày tạo", field: "created_at", timeago: true },
      ],
    };
  },
  computed: {
    manipulatedJobs() {
      return this.jobs.map((job) => {
        job.hidden_name = this.hiddenName(job.is_hidden);
        if (
          job.is_hidden === 1 ||
          job.is_hidden === "1" ||
          job.is_removed === 1 ||
          job.is_removed === "1"
        ) {
          job.color = true;
        } else {
          job.color = false;
        }
        let disabled = false;
        if (job.is_removed || job.is_refund) {
          disabled = true;
        }
        job.disabled = disabled;
        return job;
      });
    },
  },
  created() {
    this.getJobList();
  },
  methods: {
    // get danh sách jobs
    async getJobList(opts = {}) {
      const params = {
        limit: this.limit,
        user_id: this.user_id,
        object_id: this.object_id,
        order_id: this.order_id,
        is_hidden: this.is_hidden,
      };
      if (opts.is_hidden != undefined) {
        params.is_hidden = opts.is_hidden;
      }
      const queries = buildParamQuery(params);
      this.loading = true;
      const response = await jobApi.instagram.list(queries);
      this.loading = false;
      if (response?.success) {
        this.jobs = response.data || [];
      } else {
        this.$toastr.error(response?.message || "Get jobs không thành công");
      }
    },
    // ưu tiên
    async setPriorityJob(job) {
      const params = {
        id: job.id,
      };
      const response = await jobApi.instagram.priority(params);
      if (response?.success) {
        this.$toastr.success(response?.message || "Thành công");
      } else {
        this.$toastr.error(response?.message || "Thất bại");
      }
    },
    // ẩn jobs
    async hideJob(job) {
      const params = {
        id: job.id,
        is_hidden: 1,
      };
      const response = await jobApi.instagram.update(params);
      if (response?.success) {
        this.$toastr.success(response?.message || "Thành công");
        this.jobs = this.jobs.map((j) => {
          if (j.id !== job.id) {
            return j;
          } else {
            j.is_hidden = 1;
            return j;
          }
        });
      } else {
        this.$toastr.error(response?.message || "Thất bại");
      }
    },

    // mở lại jobs (nếu job ẩn)
    async unHideJob(job) {
      const params = {
        id: job.id,
        is_hidden: 0,
      };
      const response = await jobApi.instagram.update(params);
      if (response?.success) {
        this.$toastr.success(response?.message || "Thành công");
        this.jobs = this.jobs.map((j) => {
          if (j.id !== job.id) {
            return j;
          } else {
            j.is_hidden = 0;
            return j;
          }
        });
      } else {
        this.$toastr.error(response?.message || "Thất bại");
      }
    },
    // mở tất cả job ẩn
    async openAllJobHidden() {
      for (const job of this.jobs) {
        await datetimeHelper.wait(500);
        await this.unHideJob(job);
      }
    },
    // xóa job
    async removeJob(job) {
      this.$swal
        .fire({
          title: "Thông báo",
          html: `Xác nhận xóa job id <strong>${job.id}</strong>`,
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "Hủy",
          confirmButtonText: "Xác nhận",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (!result?.value) {
            return;
          }
          const params = {
            id: job.id,
            is_removed: 1,
          };
          const response = await jobApi.instagram.delete(params);
          if (response?.success) {
            this.$toastr.success(response?.message || "Thành công");
            this.jobs = this.jobs.map((j) => {
              if (j.id !== job.id) {
                return j;
              } else {
                j.is_removed = 1;
                return j;
              }
            });
          } else {
            this.$toastr.error(response?.message || "Thất bại");
          }
        });
    },
    hiddenName(id) {
      switch (id) {
        case 1:
          return "Đang ẩn";
        case "1":
          return "Đang ẩn";
        case 0:
          return "Đang mở";
        case "0":
          return "Đang mở";
        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
